import React from 'react';
import Box from '@webAtoms/Box/Box';
import PageHeading from '@webMolecules/PageHeading/PageHeading';
import UpNext from '@webMolecules/UpNext/UpNext';
import Steps, { StepsProps } from './Sections/Steps';
import VisitOurDisplay from '@webSections/VisitOurDisplay';
import * as Route from '@routes';

export interface OurProcessProps {
  title: string;
  body: string;
  steps: StepsProps;
}

const OurProcess: React.FC<OurProcessProps> = ({ title, body, steps }) => {
  return (
    <Box>
      <PageHeading title={title}>
        <Box htmlContent={body} />
      </PageHeading>

      <Steps {...steps} />

      <VisitOurDisplay />

      <UpNext
        links={[
          {
            label: 'Sustainability report',
            href: Route.sustainabilityReport(),
          },
          {
            label: 'Modular homes',
            href: Route.designs(),
          },
        ]}
      />
    </Box>
  );
};

export default OurProcess;
