import React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import Box from '@webAtoms/Box/Box';
import Container from '@webAtoms/Container/Container';
import Accordion from '@webMolecules/Accordion/Accordion';
import * as styles from './steps.module.scss';

export interface StepsProps {
  title: string;
  image?: IGatsbyImageData;
  columns: StepsColumn[];
}
export interface StepsColumn {
  title: string;
  body: string;
  parts: StepsPart[];
}

export interface StepsPart {
  title: string;
  body: string;
}

const Steps: React.FC<StepsProps> = ({ title, image, columns }) => {
  return (
    <Box>
      <Container>
        <Box className={styles.stepsInner}>
          <Box>
            {image && (
              <GatsbyImage loading="lazy" image={image} alt="Three Steps" />
            )}
          </Box>

          <Box className={styles.stepsSectionHeading}>
            <h2
              dangerouslySetInnerHTML={{
                __html: title.split('.').join('.<br />'),
              }}
            />
          </Box>

          <Box className={styles.stepsSectionSteps}>
            <ul>
              {columns.map(column => (
                <li key={column.title}>
                  <header>
                    <h3
                      dangerouslySetInnerHTML={{
                        __html: column.title.split('.').join('.<br />'),
                      }}
                    />
                    <p>{column.body}</p>
                  </header>
                  <div>
                    {column.parts.map(part => (
                      <Accordion key={part.title} title={part.title}>
                        {part.body}
                      </Accordion>
                    ))}
                  </div>
                </li>
              ))}
            </ul>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Steps;
